<template>
  <div class="notice-container">
    <el-row>
      <el-col class="notice-left" :span="17">
        <div class="content">
          <div class="notice-breadcrumb">
            <div>首页</div>
            <div>></div>
            <div>公告</div>
            <div>></div>
            <div class="last-text">公告详情</div>
          </div>
          <div class="notice-title">2021年自主产品年终经营研讨会</div>
          <div class="notice-time">
            <div>发布时间： 2021/12/31 17:25:48</div>
            <div>浏览： 96次</div>
          </div>
          <p>
            岁月不居，时节如流。里德通信2021年研发及市场总结大会于12月30号在桐庐总部如期举行，研发及市场家人们汇聚一堂，回顾一年工作，展望来年规划。
          </p>
          <div class="notice-img-1">
            <img src="./1.png" srcset="./1@2x.png 2x" alt="">
          </div>

          <p style="margin-top: 15px;">
            2021年实属不易，里德自主产品经营搏浪前行。
          </p>

          <p style="margin-top: 15px;">
            通信后备电源继续稳定增长，国产替代解决芯片难题，满足客户交付。新产品线户用储能实现批量供货。
          </p>
          <p style="margin-top: 15px;">
            最后公司经营层，李云星副总，徐龙忠总经理总结讲话。对研发及市场取得的成绩给与肯定，对新的一年做好战略规划，激励我们坚定信心，逆流前行，圆满完成2022年经营目标。不断突破，传承，创新！
          </p>
          <p style="margin-top: 15px;">
            高压储能/UPS，今年是发展元年，年轻的团队从年前组建，潜心研究，以时间换进度。产品从0-1实现跨越，赢得多客户小批量订单，呈现蓬勃发展趋势。
          </p>
          <p style="margin-top: 15px;">
            每一点成绩，都离不开整个团队辛勤的付出。当您深夜经过里德公司，灯火通明，那一定是我们研发办公室。
          </p>
          <p style="margin-top: 15px;">
            在取得成绩的同时，我们也深刻检讨工作中存在的不足：
          <div style="padding-left: 50px;margin-top: 15px;">
            <p>1：项目导入前期缺乏全面调研，开发项目商业转化率不高。</p>
            <p>2：自主产品快速发展，研发及市场相关规划化管理丞待完善。</p>
            <p>3：在性能稳定的前提下，思考如何持续降本，为客户提供高性价比产品。</p>
          </div>
          </p>
          <div class="notice-img-2">
            <img src="./2.png" srcset="./2@2x.png 2x" alt="">
            <div style="margin-top: 30px;"></div>
            <img src="./3.png" srcset="./3@2x.png 2x" alt="">
          </div>
          <p style="margin-top: 15px;">
            最后公司经营层，李云星副总，徐龙忠总经理总结讲话。对研发及市场取得的成绩给与肯定，对新的一年做好战略规划，激励我们坚定信心，逆流前行，圆满完成2022年经营目标。不断突破，传承，创新！
          </p>
          <div class="notice-footer">
            <div class="notice-prev">
              <router-link tag="span" to="/notice8">
                < 上一篇</router-link>
            </div>
            <div class="notice-next">
              <router-link tag="span" to="/notice6">下一篇 ></router-link>
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="notice-right" :span="7">
        <div class="content">
          <div class="title">最新公告</div>
          <div>
            <div class="notice-other-1 pointer">
              <router-link tag="span" to="/notice14">童年，是一只载着无数快乐的小船；童年,……</router-link>
            </div>
            <div class="notice-other-time-1">2022-06-01 15:26</div>

            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice13">小鸟为天空而歌，蜜蜂为花朵而歌，今天……</router-link>
            </div>
            <div class="notice-other-time-2">2022-05-01 10:39</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice12">为助力健康亚运建设，保障劳动者职业健……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-26 16:52</div>
            <div class="notice-other-2 pointer">
              <router-link tag="span" to="/notice11">2022年3月24日，杭州里德通信有限公司桐……</router-link>
            </div>
            <div class="notice-other-time-2">2022-04-25 11:26</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';

.notice-container {
  padding: 24px gap() 56px;
}

.notice-left {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 16px 32px 40px;
    font-size: 16px;
  }

  p {
    text-indent: 2em;
    line-height: 1.5;
  }
}

.notice-breadcrumb {
  display: flex;
  font-size: 14px;
  margin-bottom: 20px;

  .last-text {
    color: #a6a6a6;
  }
}

.notice-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.notice-time {
  display: flex;
  color: #a6a6a6;
  font-size: 14px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d4d4;

  div:first-of-type {
    margin-right: 32px;
  }
}

.notice-img-1,
.notice-img-2 {
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.notice-img-3 {
  img {
    display: block;
    margin: auto;

    &:first-of-type {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 32px;
    }
  }
}

.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 64px;
  border-top: 1px solid #d4d4d4;
}

.notice-prev {
  color: #da261d;
  cursor: pointer;
}

.notice-next {
  color: #da261d;
  cursor: pointer;
}

.notice-right {
  .content {
    background-color: #fff;
    margin-right: 12px;
    padding: 24px 24px 400px;
    box-sizing: border-box;
    font-size: 16px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
  }
}

.notice-other-time-1,
.notice-other-time-2 {
  padding: 8px 0;
  color: #a6a6a6;
  border-bottom: 1px solid #d4d4d4;
}

.notice-other-2 {
  margin-top: 32px;
}
</style>
